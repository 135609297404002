<template>
  <div>
    <div class="m-card3">
      <div class="mc-top">
        <div class="mc-top-title">
          <span class="mc-top-text mc-top-txt s-bold">用户评价</span>
          <span class="usernumber">({{evalCount}})</span>
        </div>

      </div>
      <div class="content">
        <div class="introduction-bottom-category" v-if="evalTags.length">
          <div class="top">
            <span v-for="(item, index) in evalTags" :key="index"
              >{{item.name}}（{{item.evaluateCount}}）</span
            >
          </div>
        </div>
        <div class="reply" v-for="(item, index) in list" :key="index">
          <div class="reply-content">
            <img v-if="item.avatarUrl" :src="item.avatarUrl"  class="logo" />
            <img v-else src="@/assets/img/empty.png"  class="logo">
            <div class="reply-text">
              <div class="name">{{item.name||'游客'}}   <Rate v-model="item.score" :disabled="true" class="score" v-if="!supply"/></div>

              <div class="reply-text-c">
                <span>{{item.content}}</span>
              </div>
              <div class="reply-re" v-if="item.merchantReplys&&item.merchantReplys.length">
                <div class="reply-re-c">
                  <div class="reply-re-name" v-for="(mode,i) in item.merchantReplys" :key="i">商家回复：{{mode.content}}</div>
                </div>
              </div>
              <div class="time">{{item.timeStr}}</div>
            </div>
          </div>
        </div>
        <div class="no_more mar_bot1 mar-top25" v-if="loading">
          <p>加载中...</p>
        </div>
        <div class="no_more mar_bot1 mar-top25" v-else-if="finished">
          <p>没有更多了</p>
        </div>
        <div
          class="more mar_bot1 mar-top25"
          @click="moreClick"
          v-else-if="list && list.length"
        >
          <p>加载更多</p>
        </div>
        <div class="no_more mar_bot1 mar-top25" v-else>暂无数据！</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "index",
  props: {
    evalCount: {
      type: Number,
      default: 0,
    },
    evalTags: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pro: {
      type: Boolean,
      default: false,
    },
    supply: {
      type: Boolean,
      default: false,
    },
    productId: String,
    supplyId:String
  },
  data() {
    return {
      list: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 8,
      finished: false,
      loading: false,
      score:0
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
    }),
    exhibitorId() {
      return this.$route.params.id;
    },
  },
  created() {
    Object.assign(this.$data, this.$options.data);
    this.init();
  },
  methods: {
    ...mapActions({
      graphqlPost:'graphqlPostByZXS',
    }),
    init() {
      // console.log(this.productId,'productId')
      if (this.pro == false && !this.supply) {
        this.getExData();
      } else if (this.pro == true && !this.supply) {
        this.getProductEvaluate();
      } else if (this.supply) {
        this.getSupply();
      }
    },  
    async getExData(){
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          exhibitorEvaluateQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  avatarUrl
                  content
                  createAt
                  id
                  imgs
                  isShow
                  name
                  jobTitle
                  memberId
                  merchantReplyCount
                  merchantReplys{
                    content
                  }
                  replyType
                  tags
                  score
                }
              }
            }
        }
      `;
      let where={
        "Equal":{
          "n":"exhibitorId",
          "v":this.exhibitorId
        },
        "Equal_isDeleted":{
          "n":"isDeleted",
          "v":0
        },
        "Equal_isShow":{
          "n":"isShow",
          "v":1
        },
        "Equal_parentId":{
          "n":"parentId"
        }
      }
      let order=[
        {
          "N":"createAt",
          "v":0
        }
      ]
     
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where:JSON.stringify(where),
            order:JSON.stringify(order)
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.exhibitorEvaluateQuery.query));
      this.score=res.score
      res.items.forEach(el => {
          let time=moment().from(el.createAt);
          let nowTime=moment(new Date()).diff(el.createAt,'minute');
          if(nowTime==0){
            el.timeStr='刚刚';
          }else if(time.indexOf('分钟')!=-1){
            el.timeStr=parseInt(time)+' 分钟前'
          }else if(time.indexOf('小时')!=-1&&parseInt(time)<8){
            el.timeStr=parseInt(time)+' 小时前';
          }else{
            let timeMoment=moment(el.createAt).calendar(moment(), {
                sameDay: 'HH:mm',
                lastDay: '[昨天] HH:mm',
                lastWeek: '[上周]dd',
                sameElse: 'YYYY/MM/DD',
            });
            el.timeStr=timeMoment;
          }
      });
      // console.log(res.items)
      if(this.pageIndex==1){
        this.list=res.items
      }else{
        this.list=[...new Set([...this.list,...res.items])];
      }
      this.loading=false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    async getProductEvaluate(){
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          productEvaluateQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  avatarUrl
                  content
                  createAt
                  id
                  imgs
                  isShow
                  name
                  jobTitle
                  memberId
                  merchantReplyCount
                  merchantReplys{
                    content
                  }
                  replyType
                  tags
                  score

                }
              }
            }
        }
      `;
      let where={
        "Equal":{
          "n":"productId",
          "v":this.productId
        },
        "Equal_isDeleted":{
          "n":"isDeleted",
          "v":0
        },
        "Equal_isShow":{
          "n":"isShow",
          "v":1
        },
        "Equal_parentId":{
          "n":"parentId"
        }
      }
      let order=[
        {
          "N":"createAt",
          "v":0
        }
      ]
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where:JSON.stringify(where),
            order:JSON.stringify(order)
          },
        },
      };
      let data1 = await this.graphqlPost(opt);
          console.log(data1,'评价data4444')
          let res=JSON.parse(JSON.stringify(data1.data.productEvaluateQuery.query));
          this.score=res.score
      res.items.forEach(el => {
        let time=moment().from(el.createAt);
        let nowTime=moment(new Date()).diff(el.createAt,'minute');
        if(nowTime==0){
          el.timeStr='刚刚';
        }else if(time.indexOf('分钟')!=-1){
          el.timeStr=parseInt(time)+' 分钟前'
        }else if(time.indexOf('小时')!=-1&&parseInt(time)<8){
          el.timeStr=parseInt(time)+' 小时前';
        }else{
          let timeMoment=moment(el.createAt).calendar(moment(), {
            sameDay: 'HH:mm',
            lastDay: '[昨天] HH:mm',
            lastWeek: '[上周]dd',
            sameElse: 'YYYY/MM/DD', 
          });
          el.timeStr=timeMoment;
        }
      });
      // console.log(res.items)
      if(this.pageIndex==1){
        this.list=res.items
      }else{
        this.list=[...new Set([...this.list,...res.items])];
      }
      this.loading=false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    async getSupply() {
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          supplyDemandCommentQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  content
                  createAt
                  id
                  isDeleted
                  publisher{
                    avatarUrl
                    publisherName
                    publisherNameEn
                  }
                  memberId
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "supplyDemandId",
          v: this.supplyId,
        },
        Equal_isDeleted: {
          n: "isDeleted",
          v: 0,
        },
      };
      let order = [
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data1 = await this.graphqlPost(opt);
      console.log(data1, "评价dataSupply");
      let res = JSON.parse(
        JSON.stringify(data1.data.supplyDemandCommentQuery.query)
      );
      this.score = res.score;
      res.items.forEach((el) => {
        let time = moment().from(el.createAt);
        let nowTime = moment(new Date()).diff(el.createAt, "minute");
        if (nowTime == 0) {
          el.timeStr = "刚刚";
        } else if (time.indexOf("分钟") != -1) {
          el.timeStr = parseInt(time) + " 分钟前";
        } else if (time.indexOf("小时") != -1 && parseInt(time) < 8) {
          el.timeStr = parseInt(time) + " 小时前";
        } else {
          let timeMoment = moment(el.createAt).calendar(moment(), {
            sameDay: "HH:mm",
            lastDay: "[昨天] HH:mm",
            lastWeek: "[上周]dd",
            sameElse: "YYYY/MM/DD",
          });
          el.timeStr = timeMoment;
        }
        if(el.publisher&&el.publisher.avatarUrl){
          el.avatarUrl=el.publisher.avatarUrl
        }
        if(el.publisher&&el.publisher.publisherName){
          el.name=el.publisher.publisherName
        }
      });
      // console.log(res.items)
      if (this.pageIndex == 1) {
        this.list = res.items;
      } else {
        this.list = [...new Set([...this.list, ...res.items])];
      }
      this.loading = false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    moreClick(){
      if (this.finished) return;
      this.pageIndex += 1;
      if (this.pro==false){
        this.getExData();
      }
      else{
        this.getProductEvaluate()
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.usernumber {
  margin-left: 10px;
  font-size: 14px;
  color: #666;
}
.content {
  padding: 15px 20px;
}
.introduction-bottom-category {
  .top {
    display: flex;
    flex-wrap: wrap;
    span {
      padding: 2px 8px;
      background-color: #ff9b89;
      color: #fff;
      border-radius: 3px;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 14px;
      font-size: 12px;
    }
  }
}
.reply {
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  &-content {
    padding: 20px 0;
    display: flex;
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-left: 5px;
      margin-right: 22px;
    }
  }
}
.reply-text{
  width: 100%;
}
.reply-text-c {
  color: #333;
  margin-top: 10px;
}
.name{
  position: relative;
  .score{
    position: absolute;
    right: 0;
  }
}
.reply-re {
  width: 768px;
  margin-top: 10px;
  background-color: #f5f5f5;
  .reply-re-c {
    padding: 16px 0 14px 10px;
  }
  .reply-re-name {
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
.time {
  color: #999999;
  font-size: 12px;
  margin-top: 15px;
}
.no-more {
  width: 100%;
  font-size: 12px;
  color: #999999;
  text-align: center;
  margin: 20px 0;
}
</style>
